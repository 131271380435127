export const initializeGlobalErrorHandlers = () => {
  window.addEventListener('error', (event) => {
    console.error('Global error:', event.error)

    event.preventDefault()
  })

  window.addEventListener('unhandledrejection', (event) => {
    console.error('Promise error:', event.reason)

    event.preventDefault()
  })
}
