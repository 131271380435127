import SentryInit from './sentry.init'

import { state } from './store/state'
import { isTest } from './who-am-i'
import { PartialDirectorPort } from './business-logic/director/director'
import { wrap } from './store/notifications'
import { info } from './logger'
import { startSpanManually } from './tracer'
import { globalPool } from './business-logic/pool-aggregator'
import { initializeGlobalErrorHandlers } from './error-handler'
import { initialize } from './business-logic/director/init/initialize'

initializeGlobalErrorHandlers()
SentryInit()

if (!isTest()) {
  const setupWindowObjects = () => {
    state.setGlobal('pool', globalPool)
  }

  const fn = () => {
    info('Application started')
    state.setPorts(new PartialDirectorPort())
    initialize()

    setupWindowObjects()
  }

  startSpanManually({ name: 'boot' }, (span) => {
    const unsubscribe = state.uiFullyMounted.listen(() => {
      unsubscribe()
      span.end()
    })

    wrap(fn, state)()
  })
}
