import { ApplicationSettings } from '../../business-logic/types'
import { ActiveCommand, CommandHandler, PoolCommand, PoolResult, Pools, WorkerResult } from '../types'

export let settings: ApplicationSettings
export let accessToken: string

export class InitWorkers implements CommandHandler, ActiveCommand {
  readonly command: PoolCommand

  constructor(public readonly data: { settings: ApplicationSettings; accessToken: string }) {
    this.command = {
      command: {
        type: 'INIT_WORKERS',
        data: this.data,
      },
    }
  }

  async handle(): Promise<WorkerResult> {
    settings = this.data.settings
    accessToken = this.data.accessToken

    return { data: 'done' }
  }

  async runOnPool(pools: Pools): Promise<PoolResult> {
    return pools.masterDb.Exec(this.command)
  }
}
