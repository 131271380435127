import fastEqual from 'fast-deep-equal'
import { flatObject } from 'common/src/logger'
import { jwtFromLocalStorage, jwtPayload } from '../../../server'
import { NullUser, User } from '../../../store/user'
import { logUsername } from '../../../tracer'
import { privateState } from '../director'
import { InitWorkers } from '../../../workers/commands/init-workers'
import { info } from '../../../logger'

export const loadCurrentUser = async (_ = privateState, jwtProvider = jwtPayload) => {
  const jwt = jwtProvider()
  const { settings, threads } = _

  _.state.checkLoggedUser(jwt?.payload.userId)

  _.state.lookups.listen((lookups) => {
    const user = lookups.userByUsername(jwt?.payload.username)

    if (user instanceof NullUser) {
      return
    }

    lookups.updateWebSectionsByUser(`${user.userId}`)
    logUsername(user.username)

    const currentUser = _.state.loggedUser.get()
    if (fastEqual({ ...currentUser }, { ...user })) {
      return
    }

    _.state.loggedUser.set(new User(user.username, user.userId, user.firstName, user.lastName, user.fq))

    info('User loaded', {
      tags: {
        ...flatObject(user),
      },
    })
  })

  await threads.Exec(new InitWorkers({ settings, accessToken: jwtFromLocalStorage() || '' }))
}
