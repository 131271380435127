import { baseUrl, isChrome } from '../../../who-am-i'
import { provider as remoteProvider } from '../../remote'
import { server } from '../../../server'
import { activeSpan, activeSpanAsync, logScreenResolution } from '../../../tracer'
import { db, provider as localProvider } from '../../db-facade'
import { GlobalEnterKeyHandler } from '../../global-enter-key-handler'
import { privateState } from '../director'
import { printWelcomeMessage } from '../welcome-message'
import { attachSocketEvents } from './attach-socket-events'
import { loadCurrentUser } from './load-current-user'
import { validateAndClearBags } from './validate-and-clear-bags'
import { loadSavedSearches } from '../../../store/user-data/saved-searches-updater'

export const initialize = async (_ = privateState) => {
  const {
    state,
    threads,
    authorize,
    downloadChanges,
    listenToLiveStream,
    serverFactory,
    setFirstConnectionStatus,
  } = _

  state.setGlobal('remoteProvider', remoteProvider)
  state.setGlobal('localProvider', localProvider)

  state.isLoading.set(true)

  activeSpan('load from local storage', () => {
    state.settings.restore()
    state.dirtyBag.restore()
    state.stagingArea.restore()
    state.userData.restore()
  })

  loadSavedSearches(state, threads)

  const commitId = await activeSpanAsync('attach socket', async () => {
    printWelcomeMessage()
    logScreenResolution(window)

    /*                                                            */ console.time('database loaded in: ') // prettier-ignore

    const settings = {
      baseUrl: baseUrl(),
      isChrome: isChrome(),
    }

    _.settings = settings
    _.server = await server(() => serverFactory(settings.baseUrl))

    loadCurrentUser(_)

    attachSocketEvents(_)
    setFirstConnectionStatus(_)
    listenToLiveStream(_)

    const { data: commitId } = await db(threads, state).getMaxCommitId()
    state.isAFreshStart = commitId < 0
    return commitId
  })

  await activeSpanAsync('authorize', async () => {
    await authorize(async () => _.server)
  })

  await downloadChanges(commitId, _)

  /*                                                              */ console.timeEnd('database loaded in: ')

  await activeSpanAsync('validate and clear bags', async () => {
    await validateAndClearBags(_)
  })

  GlobalEnterKeyHandler.buildInstance(state)

  state.isLoading.set(false)
}
