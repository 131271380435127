import { reAuthenticate, reAuthenticateDisable } from '../../../server'
import { privateState } from '../director'

export const attachSocketEvents = (_ = privateState, doLog = console.log, doWarn = console.warn) => {
  const { state, server, authenticationCallBack } = _

  const io = server.io

  if (!io) return

  io.on('disconnect', () => {
    doWarn('socket:: disconnected, waiting for reconnection')
    reAuthenticateDisable()
    state.connectionStatus.set('disconnected')
  })

  io.on('connect', (_reAuthenticate = reAuthenticate) => {
    _reAuthenticate(server).then(() => {
      authenticationCallBack(_, doLog)
    })
  })
}
