import { privateState } from '../director'

export const validateAndClearBags = async (_ = privateState) => {
  const actualDirties: string[] = Object.keys(_.state.dirtyBag.data.get() || {})
  const actualStaging: string[] = Object.keys(_.state.stagingArea.data.get() || {})

  const toValidate = Array.from(new Set([...actualDirties, ...actualStaging]))

  const validate = toValidate.map(async (id: any) => _.server.service('content').get(id))
  const res = await Promise.all(validate)

  const validIds = res.map((r: any) => r.contents.map((c: any) => c.id)).flat()
  const validDirties = actualDirties.filter((id: any) => validIds.includes(id))
  const validStaging = actualStaging.filter((id: any) => validIds.includes(id))

  actualDirties.filter((id) => !validDirties.includes(id)).forEach((id) => _.state.dirtyBag.remove(id))
  actualStaging.filter((id) => !validStaging.includes(id)).forEach((id) => _.state.stagingArea.remove(id))
}
